/* @import './fonts/gotham.css'; */

body {
    margin: 0;
}

#payment-method-container-payment-page .adyen-checkout-input__inline-validation--invalid [alt="field_error"] {
    display: none;
}

#squareCheckBox {
    accent-color: rgba(0, 164, 145, 500);
    width: 20px;
    margin-left: 0px;
}

#expressMethod-button .paypal-buttons {
    position: sticky;
}